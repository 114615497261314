import {
  collapseFieldArray,
  paramsToQueryString,
  queryStringToObject,
} from '~/util';

const brandLogos = [
  'the-australian',
  '7news',
  'herald-sun',
  'money',
  'the-daily-telegraph',
  'mozo',
  'ifa',
  'the-courier-mail',
  'the-advertiser',
  'vogue',
];

export const state = () => ({
  affiliate: null,
  appQuery: '',
  brandLogos,
  brandSwitcherLogos: brandLogos.filter(
    (logo) => !['mozo', 'ifa'].includes(logo)
  ),
  gutterWidth: 0,
  hasMobileLayout: true,
  webfontActive: false,
  hasOffer: false,
  isReturning: false,
  hasPartnerCookie: null,
  partnership: null,
  navBarHeight: 160,
  transitionDirection: null,
  showGrid: false,
  showVideoModal: false,
  theme: {
    background: null,
    logoBackground: null,
    text: null,
    textActive: null,
  },
});

export const getters = {
  affiliate: (state) => state.affiliate,
  affiliateSlug: (state) => state.affiliate?.affiliateSlug,
  appQuery: (state) => state.appQuery,
  brandLogos: (state) => state.brandLogos,
  brandSwitcherLogos: (state) => state.brandSwitcherLogos,
  gutterWidth: (state) => state.gutterWidth,
  hasMobileLayout: (state) => state.hasMobileLayout,
  webfontActive: (state) => state.webfontActive,
  hasOffer: (state) => state.hasOffer,
  isReturning: (state) => state.isReturning,
  hasPartnerCookie: (state) => state.hasPartnerCookie,
  partnership: (state) => state.partnership,
  transitionDirection: (state) => state.transitionDirection,
  showGrid: (state) => state.showGrid,
  showVideoModal: (state) => state.showVideoModal,
  theme: (state) => state.theme,
};

export const mutations = {
  affiliate(state, value) {
    state.affiliate = value;
  },
  appQuery(state, params = []) {
    const oldQueryString = queryStringToObject(state.appQuery);
    state.appQuery = paramsToQueryString({
      ...oldQueryString,
      ...params.reduce((prev, entry) => {
        prev[entry.key] = entry.value;
        return prev;
      }, {}),
    });
  },
  gutterWidth(state, value) {
    state.gutterWidth = value;
  },
  hasMobileLayout(state, value) {
    state.hasMobileLayout = value;
  },
  webfontActive(state, value) {
    state.webfontActive = value;
  },
  hasOffer(state, value) {
    state.hasOffer = value;
  },
  isReturning(state, value) {
    state.isReturning = value;
  },
  hasPartnerCookie(state, value) {
    state.hasPartnerCookie = value;
  },
  partnership(state, value) {
    state.partnership = value;
  },
  transitionDirection(state, value) {
    state.transitionDirection = value;
  },
  showGrid(state, value) {
    state.showGrid = value;
  },
  showVideoModal(state, value) {
    state.showVideoModal = value;
  },
  theme(state, value) {
    state.theme = value;
  },
};

export const actions = {
  async nuxtServerInit({ dispatch }) {
    await Promise.all([
      // dispatch('partnerships/getPartnershipData', context),
      dispatch('globals/getGlobalsData'),
    ]);
  },
  setAffiliate({ commit }, value) {
    commit('affiliate', value);
  },
  setAppQuery({ commit }, value) {
    commit('appQuery', value);
  },
  setGutterWidth({ commit }, value) {
    commit('gutterWidth', value);
  },
  setHasMobileLayout({ commit }, value) {
    commit('hasMobileLayout', value);
  },
  setWebfontActive({ commit }) {
    commit('webfontActive', true);
  },
  setHasOffer({ commit }, value) {
    commit('hasOffer', value);
  },
  setIsReturning({ commit }, value) {
    commit('isReturning', value);
  },
  setHasPartnerCookie({ commit }, value) {
    commit('hasPartnerCookie', value);
  },
  setPartnership({ commit }, value) {
    commit('partnership', value);
  },
  setTheme({ commit }, value) {
    commit('theme', collapseFieldArray(value));
  },
  setTransitionDirection({ commit }, { from, to }) {
    commit('transitionDirection', to < from ? '<' : '>');
  },
  toggleGrid({ commit, state }) {
    commit('showGrid', !state.showGrid);
  },
  toggleVideoModal({ commit, state }) {
    commit('showVideoModal', !state.showVideoModal);
  },
};

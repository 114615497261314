/*
  eventObject = {
    event: 'eventName'
  }
*/
const pushCustomGTMEvent = (eventObject) => {
  if (window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push(eventObject);
  }
};

export default (ctx, inject) => {
  inject('pushCustomGTMEvent', pushCustomGTMEvent);
};

import { mapActions } from 'vuex';

import { isIe11 } from '~/util';

export default {
  watch: {
    $mq($mq) {
      this.$store.commit(
        'hasMobileLayout',
        ['default', 'sm', 'md'].includes($mq)
      );
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown);

    if (isIe11()) {
      document.body.classList.add('ie-11');
    }

    this.$nuxt.$on('showChat', () => {
      if (typeof window !== 'undefined') {
        if (window.Intercom) {
          window.Intercom('show');
        } else {
          console.error('No chat implementation available.');
          window.location.href =
            'mailto:support@safewill.com?subject=Support%20Query';
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
    this.$nuxt.$off('showChat');
  },
  methods: {
    ...mapActions(['toggleGrid', 'setWebfontActive']),
    onKeydown(e) {
      const { ctrlKey, keyCode } = e;

      switch (keyCode) {
        // Left
        case 37:
          break;
        // Up
        case 38:
          break;
        // Right
        case 39:
          break;
        // Down
        case 40:
          break;
        // 0-9
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 54:
        case 55:
        case 56:
        case 57:
          break;
        // G
        case 71:
          if (
            ctrlKey &&
            /localhost|test|herokuapp/.test(window.location.host)
          ) {
            e.preventDefault();

            this.toggleGrid();
          }
          break;
        // Space
        case 32:
          break;
        default:
          break;
      }
    },
  },
};

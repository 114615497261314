const {
  IntrospectionFragmentMatcher,
  InMemoryCache,
} = require('apollo-cache-inmemory');
const introspectionQueryResultData = require('./fragmentTypes.json');

export default function ({ $config }) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  return {
    httpEndpoint: `${$config.craftUrl}/api`,
    cache: new InMemoryCache({ fragmentMatcher }),
  };
}

const scroll = {
  element:
    window.document.scrollingElement ||
    window.document.body ||
    window.document.documentElement,
  top() {
    return (
      window.pageYOffset ||
      (document.documentElement.clientHeight
        ? document.documentElement.scrollTop
        : document.body.scrollTop) ||
      0
    );
  },
  trigger() {
    window.dispatchEvent(new CustomEvent('scroll'));
  },
};

export default (ctx, inject) => {
  inject('scroll', scroll);
};

function getLanguageByLocaleCode(localeCode, locales) {
  const locale = locales.find((locale) => locale.code === localeCode);
  return locale.iso ? locale.iso.split('-')[0] : null;
}

export default function ({ app }) {
  const locale = app.i18n.locale;
  app.$pushCustomGTMEvent({
    language: getLanguageByLocaleCode(locale, app.i18n.locales),
    country: locale,
  });

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    app.$pushCustomGTMEvent({
      language: getLanguageByLocaleCode(newLocale, app.i18n.locales),
      country: newLocale,
    });
  };
}

import { saveAnonymousProfile } from '~/util';

export default {
  created() {
    const utmKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ];

    const utmKvps = utmKeys
      .map((key) => [key, this?.$route?.query[key] || null])
      .filter(([_key, value]) => value != null);

    if (utmKvps.length === 0) {
      return;
    }

    const utm = Object.fromEntries(utmKvps);

    saveAnonymousProfile({ utm });
  },
};

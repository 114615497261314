import { collapseFieldArray } from '~/util';

export const state = () => ({
  bannerName: '',
  bannerLogo: null,
  bannerText: '',
});

export const getters = {
  bannerName: (state) => state.bannerName,
  bannerLogo: (state) => state.bannerLogo,
  bannerText: (state) => state.bannerText,
};

export const mutations = {
  bannerName(state, value) {
    state.bannerName = value;
  },
  bannerLogo(state, value) {
    state.bannerLogo = value;
  },
  bannerText(state, value) {
    state.bannerText = value;
  },
};

export const actions = {
  setBannerName({ commit }, value) {
    commit('bannerName', value);
  },
  setBannerLogo({ commit }, value) {
    commit('bannerLogo', collapseFieldArray(value));
  },
  setBannerText({ commit }, value) {
    commit('bannerText', value);
  },
};
